/* globalStyles.css */

body {
  font-family: 'Inter', sans-serif;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

select::-moz-focus-outer, select::-moz-focus-inner{
  outline: none;
}

select:focus{
  outline: none;
}
.MuiOutlinedInput-input {
  padding: 10px !important;
  display: flex;
  align-items: center;
  outline: none !important;
}

th, td{
  text-align: center !important;
}
